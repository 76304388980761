#txtGmailSearched,
#txtGmailSearched:focus {
    border-bottom: 1px solid gray !important;
    width: 45vw;
}

#txtGmailSearched::placeholder,
#txtGmailSearched:focus::placeholder {
    color: gray;
}

.card.shadow:last-child {
    margin-bottom: 30px;
}