.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


/* Hide the default scrollbar */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

/* Hide the default scrollbar */
body {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1; /* Handle color, Track color */
}

    /* Track */
    body::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    body::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
    }

        /* Handle on hover */
        body::-webkit-scrollbar-thumb:hover {
            background: #555;
        }


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ReactModal__Overlay {
  z-index: 999999 !important;
  display: 'flex';
  justify-content: 'center';
  align-content: 'center';
}

.swal2-container {
  z-index: 9999 !important;
}

.swal2-html-container::-webkit-scrollbar {
  display: none;
}

.swal2-html-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}