#txtSearchSlots{
    border: 1px solid lightgray;
    border-radius: 3px;
}

#txtSearchSlots:focus{
    border: 1px solid lightgray !important;
}

#txtSearchSlots::placeholder{
    color:lightgray;
    padding-left: 4px;
}